<template>
  <div class="create-waiver-page">
    <BackTitle confirm-click @click="goBack"> Create Waiver </BackTitle>
    <WaiverForm
      :value="waiver"
      :is-submitting="isSubmitting"
      @submit="submit"
    />
    <div class="create-waiver-page__fixed" data-v-step="16" />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import WaiverForm from "@/components/waivers/WaiverForm";
import { NEW_WAIVER_MOCK } from "@/helpers/mocks";
import { mapActions } from "vuex";

export default {
  name: "CreateWaiverPage",
  components: { WaiverForm, BackTitle },
  data() {
    return {
      waiver: { ...NEW_WAIVER_MOCK },
      isSubmitting: false,
    };
  },
  methods: {
    ...mapActions({
      createWaiver: "waivers/createWaiver",
    }),
    goBack() {
      this.$router.push({
        name: "Waivers",
      });
    },
    async submit(data) {
      try {
        this.isSubmitting = true;
        await this.createWaiver(data);
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-waiver-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;

  &__fixed {
    position: fixed;
    bottom: 10px;
    right: 10px;
  }
}
</style>
